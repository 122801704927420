import React, { useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import logo from '../../assets/images/logo.png';
import authBg from '../../assets/images/auth-bg.jpg';
import { DefaultInput } from '../../components/DefaultInput/index.js';
import { DefaultButton } from '../../components/DefaultButton/index.js';
import { ScrollContent } from '../../components/ScrollContent/index.js';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack'
import gtm from '../../lib/gtm';
const Login = (props) => {
    const navigate = useNavigate();
    const { platform, isExpired } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const isMountedRef = useIsMountedRef();
    const { login, openForgot } = useAuth();

    useEffect(() => {
        console.log("Is Expired", isExpired)
        if (isExpired) {
            enqueueSnackbar("Your Login is expired, Please login again", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        gtm.push({ event: 'page_view' });
    }, []);


    return (
        <Grid container>
            <Grid item xs={6}>
                <Stack
                    height={'100vh'}
                    alignItems={'center'}
                    justifyContent={'center'}

                >
                    <Stack
                        py={0}
                        width={'100%'}
                        height={'100%'}
                        maxWidth={'300px'}
                        sx={{
                            justifyContent: 'center'
                        }}
                    >
                        <Box
                            mb={12}
                            sx={{
                                img: {
                                    maxWidth: '190px',
                                    maxHeight: '35px',
                                },
                            }}
                        >
                            <img src={logo} alt='logo' />
                        </Box>
                        {/* <ScrollContent backgroundColor='yellow'> */}
                        <Stack spacing={3}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    submit: null
                                }}
                                validationSchema={Yup
                                    .object()
                                    .shape({
                                        email: Yup
                                            .string()
                                            .email('Must be a valid email')
                                            .max(255)
                                            .required('Email is required'),
                                        password: Yup
                                            .string()
                                            // .matches("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{18,}$")
                                            .required('Password is required')
                                    })}
                                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                    try {
                                        var data = await login(values.email, values.password);
                                        console.log("LOGIN RES:", data)
                                        if (data !== undefined && data.success && isMountedRef.current) {
                                            setStatus({ success: true });
                                            setSubmitting(false);
                                        } else {
                                            enqueueSnackbar(data.message, {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'error'
                                            });
                                            setStatus({ success: false });
                                            setErrors({ submit: data.message });
                                            setSubmitting(false);
                                        }

                                    } catch (err) {
                                        console.log("inCATCH", err);
                                        console.error(err);
                                        if (isMountedRef.current) {
                                            setStatus({ success: false });
                                            setErrors({ submit: err.message });
                                            setSubmitting(false);
                                        }
                                    }
                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form
                                        noValidate
                                        onSubmit={handleSubmit}
                                        {...props}
                                    >
                                        <Stack
                                            spacing={3}
                                        >
                                            <DefaultInput
                                                label={'User Name'}
                                                placeholder={'Email address'}
                                                name="email"
                                                value={values.email}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}

                                            />
                                            <DefaultInput
                                                label={'Password'}
                                                placeholder={'************'}
                                                type={'password'}
                                                name="password"
                                                value={values.password}
                                                error={Boolean(touched.password && errors.password)}
                                                helperText={touched.password && errors.password}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <DefaultButton
                                                variant={'contained'}
                                                type={'submit'}
                                                disabled={isSubmitting}
                                            >
                                                Sign In
                                            </DefaultButton>
                                            <Link to={'/authentication/forgot-password'}>
                                                <Typography
                                                    sx={{
                                                        color: 'secondary.main',
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    Forgot Password?
                                                </Typography>
                                            </Link>
                                            <Typography
                                                sx={{
                                                    a: {
                                                        color: 'secondary.main',
                                                    },
                                                }}
                                            >
                                                Not a Member?{' '}
                                                <Link to={'/authentication/register'}>
                                                    Sign Up
                                                </Link>
                                            </Typography>
                                        </Stack>

                                    </form>
                                )}
                            </Formik>

                        </Stack>
                        {/* </ScrollContent> */}
                        <Typography
                            fontWeight={700}
                            href={'https://signaltracks.com/'}
                            component={'a'}
                            target={'_blank'}
                            mt={4}
                        >
                            signaltracks.com
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack
                    height={'100%'}
                    sx={{
                        boxShadow: '0 3px 6px rgba(0 0 0 / 16%)',
                        img: {
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        },
                    }}
                >
                    <img src={authBg} alt='auth bg' />
                </Stack>
            </Grid>
        </Grid>
    );
};

export default Login;