import React, { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Grid } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import AccountPopover from './AccountPopover';
import Logo from '../../assets/images/logo.png';
import api from '../../lib/axios';
import AppMenu from './AppMenu';


const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    borderBottom: `2px solid #D5D5D5`,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));


const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const [sites, setSites] = useState([]);

  const location = useLocation()
  const naviate = useNavigate()

  const getSites = useCallback(async () => {
    try {
      const res = await api.get('/apis/companysites/byCompany/')

      if (res.data.success) {
        var data = res.data.data
        console.log("getSites :", data)
        if (!localStorage.getItem("timeZone") || localStorage.getItem("timeZone") === undefined || localStorage.getItem("timeZone") === "") {
          localStorage.setItem("timeZone", data[0].timeZone)
        }
        if (!localStorage.getItem("assetTrackingEnabled") || localStorage.getItem("assetTrackingEnabled") === undefined || localStorage.getItem("assetTrackingEnabled") === "") {
          localStorage.setItem("assetTrackingEnabled", data[0].assetTrackingEnabled)
        }
        if (!localStorage.getItem("siteID") || localStorage.getItem("siteID") === undefined || localStorage.getItem("siteID") === "") {
          localStorage.setItem("siteID", data[0]._id)
          window.location.href = "/"
        } else {
          localStorage.setItem("assetTrackingEnabled", data.find((e) => e._id + "" === localStorage.getItem("siteID")).assetTrackingEnabled)
          console.log("UPDATED assetTrackingEnabled :", data.find((e) => e._id + "" === localStorage.getItem("siteID")).assetTrackingEnabled)
        }
        setSites(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("role") != "Standard") {
      getSites();
    }
  },
    []);


  return (
    
    <DashboardNavbarRoot {...other} sx={{ minHeight: 60, maxHeight: 60, backgroundColor: '#F8F8FA' }}>
      <Toolbar sx={{ minHeight: 60, maxHeight: 60 }}>
        {/* 
        <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton> */}


        {/* <Box
          sx={{
            flexGrow: 2,
            ml: 2
          }}
        /> */}
        <Grid
          container
          direction="row"
          justifyContent={"flex-start"}
          alignContent={"center"}
        >
          <Grid
            width={"290px"}
            item
            alignItems={"center"}
          >
            <RouterLink to="/">
              <img
              style={{
                
              }}
                src={Logo}
                alt="Logo"
                height="30"
              />
            </RouterLink>

          </Grid>
        </Grid>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2 }}>
        {/* {(localStorage.getItem("isLogin") == "true") && (<AccountPopover />)} */}
        <AccountPopover sites={sites} />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
