import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';

import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
// import UserManage from './views/User/UserManage';

import { useNavigate, useLocation } from 'react-router-dom';
import PrintLayout from './components/dashboard/PrintLayout';



function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}
const GeoMap = Loadable(lazy(() => import('./views/Settings/GeoMap')));

const Register = Loadable(lazy(() => import('./views/Register/Register')));
const Login = Loadable(lazy(() => import('./views/Login/Login')));
const ForgotPassword = Loadable(lazy(() => import('./views/Forgot/Forgot')));
const ResetPassword = Loadable(lazy(() => import('./views/ResetPassword/ResetPassword')));
const Success = Loadable(lazy(() => import('./views/Success/Success')));
const ChangePassword = Loadable(lazy(() => import('./views/ChangePassword/ChangePassword')));
const EditProfile = Loadable(lazy(() => import('./views/EditProfile/EditProfile')));

const CompanyDetails = Loadable(lazy(() => import('./views/Company/CompanyDetails')));
const CompanyManage = Loadable(lazy(() => import('./views/Company/CompanyManage')));

const UserList = Loadable(lazy(() => import('./views/User/UserList')));
const UserManage = Loadable(lazy(() => import('./views/User/UserManage')));

const CompanySiteManage = Loadable(lazy(() => import('./views/Company/SitesAndZones/CompanySiteManage')));
const CompanySiteDetails = Loadable(lazy(() => import('./views/Company/SitesAndZones/CompanySiteDetails')));

const PasswordSecurityForm = Loadable(lazy(() => import('./views/Security/PasswordSecurityForm')));

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const SiteShiftManage = Loadable(lazy(() => import('./views/Company/SitesAndZones/Shifts/SiteShiftManage')));

const UserDetails = Loadable(lazy(() => import("./views/User/UserDetails")));
const Dashboard = Loadable(lazy(() => import("./views/Dashboard/Dashboard")));

const Setting = Loadable(lazy(() => import("./views/Settings/Settings")));
const Assets = Loadable(lazy(() => import("./views/Assets/Assets")));
const Tracking = Loadable(lazy(() => import("./views/Tracking/Tracking")));
const TrackingFullView = Loadable(lazy(() => import("./views/Tracking/TrackingFullView")));
const Reporting = Loadable(lazy(() => import("./views/Reporting/Reporting")));
const PrintInvoiceDetails = Loadable(lazy(() => import("./views/Reporting/PrintInvoiceDetails")));
const Sensor = Loadable(lazy(() => import("./views/Sensor/Sensor")));
const TempSensorReading = Loadable(lazy(() => import("./views/TempSensorReading/TempReading")));

const NotificationList = Loadable(lazy(() => import("./views/Notifications/NotificationList")));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'success',
        element: <Success />
      }
    ]
  },
  // {
  //   path: 'dashboard',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: '/dashboard',
  //       element: <Dashboard />
  //     },
  //     {
  //       path: '/dashboard/profile',
  //       element: <Profile />
  //     },
  //     {
  //       path: '/dashboard/resetpassword',
  //       element: <ResetPassword />
  //     }
  //   ]
  // },
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/settings',
        element: <Setting />
      },
    ]
  },
  {
    path: 'sensor_readings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/sensor_readings',
        element: <TempSensorReading />
      },
    ]
  },
  {
    path: 'profile',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/profile/editProfile',
        element: <EditProfile />
      },
      {
        path: '/profile/changePassword',
        element: <ChangePassword />
      },
    ]
  },
  {
    path: 'assets',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/assets',
        element: <Assets />
      },
    ]
  },
  {
    path: 'reporting',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/reporting',
        element: <Reporting />
      },
    ]
  },
  {
    path: 'reporting',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/reporting/print',
        element: <PrintInvoiceDetails />
      },
    ]
  },
  {
    path: 'sensor',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/sensor',
        element: <Sensor />
      },
    ]
  },
  {
    path: 'tracking',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/tracking',
        element: <Tracking />
      },
      {
        path: '/tracking/TrackingFullView',
        element: <TrackingFullView />
      },
      {
        path: '/tracking/Map',
        element: <GeoMap />
      },
    ]
  },
  {
    path: '/notification',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/notification',
        element: <NotificationList />
      },
    ]
  },
  {
    path: 'users',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/users',
        element: <UserList />
      },
      {
        path: '/users/manage',
        element: <UserManage />
      },
      {
        path: '/users/details',
        element: <UserDetails />
      }
    ]
  },
  // {
  //   path: 'loginlogs',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: '/loginlogs',
  //       element: <LoginLogs />
  //     },
  //   ]
  // },
  {
    path: 'passwordsecurity',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/passwordsecurity',
        element: <PasswordSecurityForm />
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        // element: <FloorView />
        element: <Tracking />
      },
    ]
  },
  {
    path: 'company',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/company',
        element: <CompanyDetails />
      },
      {
        path: '/company/manage',
        element: <CompanyManage />
      },
      {
        path: '/company/sites/details',
        element: <CompanySiteDetails />
      },
      {
        path: '/company/sites/manage',
        element: <CompanySiteManage />
      },

      {
        path: '/company/sites/shift/manage',
        element: <SiteShiftManage />
      },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '/',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
