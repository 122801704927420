import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box,Link, Button, Divider, FormHelperText, TextField, Typography } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';



var momentDurationFormatSetup = require("moment-duration-format");
var momentRange = require('moment-range');
var Moment = require("moment");
momentDurationFormatSetup(Moment);
const moment = momentRange.extendMoment(Moment);

const LoginAuth = (props) => {
  const isMountedRef = useIsMountedRef();
  const { auth } = useAuth();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [timeLeft, setTimeLeft] = useState(parseInt(localStorage.getItem("otpExpiresIn")))
  // const [timeLeft, setTimeLeft] = useState(2)
  const [reminingTime, setReminingTime] = useState("")

  useEffect(() => {
    console.log("updated", timeLeft)
    if (timeLeft < 0) return;
    console.log("updated 1", timeLeft)
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      setReminingTime(moment.duration(timeLeft, 'seconds').format("mm:ss", { trim: false }))
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <Formik
      initialValues={{
        otp: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          otp: Yup
            .string()
            .max(6)
            .min(6)
            .required('OTP is required')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          var data = await auth(values.otp);
          console.log("VARIFY OTP", data)
          if (data !== undefined && data.success && isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          } else {
            setStatus({ success: false });
            setErrors({ submit: data.message });
            setSubmitting(false);
          }

        } catch (err) {
          console.log("inCATCH");
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoFocus
            error={Boolean(touched.otp && errors.otp)}
            fullWidth
            helperText={touched.otp && errors.otp}
            label="OTP"
            margin="normal"
            name="otp"
            disabled={timeLeft <= 0}
            onBlur={handleBlur}
            onChange={handleChange}
            type="number"
            value={values.otp}
            variant="outlined"
          />

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting || timeLeft <= 0}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Verify
            </Button>
          </Box>
          <Divider sx={{ my: 3 }} />
          {
            timeLeft <= 0 ?
              <Button
                color="primary"
                fullWidth
                onClick={async (e) => {
                  try {
                    var data = await auth(values.otp, true);
                    // timeLeft = parseInt(data.otpExpiresIn)
                    console.log("LOGIN AUTH RESEND:", "data:", data, "EXP:", data.data.otpExpiresIn, "TL:", timeLeft)
                    // data = data.data
                    if (data !== undefined && data.success) {
                      setTimeLeft(parseInt(data.data.otpExpiresIn))
                      forceUpdate()
                    }
                  } catch (err) {
                    console.log("inCATCH");
                    console.error(err);
                  }
                }}
                size="large"
                type="submit"
                variant="contained"
              >
                Resend Code
              </Button>
              :
              <Typography
                color="textPrimary"
                variant="h6"
              >
                OTP Expires In : {reminingTime}
              </Typography>

          }
          <Link
            color="textSecondary"
            component={RouterLink}
            sx={{ mt: 1 }}
            onClick={(e) => {
              window.location.reload()
            }}
            to="#"
            variant="body2"
          >
            BACK
          </Link>


        </form>
      )}
    </Formik>
  );
};

export default LoginAuth;
