import React, { useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useRoutes, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import GlobalStyles from './components/GlobalStyles';

import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createTheme } from './theme';
// import { Header } from './components/Header/Header.jsx';
import { Header } from './components/Header/index.js';
import { Footer } from './components/Footer/index.js';
const App = () => {
  const location = useLocation();
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  // const theme = createTheme({
  //   direction: settings.direction,
  //   responsiveFontSizes: settings.responsiveFontSizes,
  //   roundedCorners: settings.roundedCorners,
  //   theme: settings.theme,
  // });

  return (
    <div className={'app'}>
      {!location.pathname.includes('authentication') && !location.pathname.includes('reporting/print') && <Header />}
      <main className={'main'}>
        <SnackbarProvider
          dense
          maxSnack={3}
        >
          {auth.isInitialized ? content : <SplashScreen />}
        </SnackbarProvider>
      </main>
   {   !location.pathname.includes('reporting/print') &&  <Footer />}
    </div>

  );
};

export default App;
