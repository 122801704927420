import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import { Header } from '../Header/Header.jsx';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'block',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '60px',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '0px'
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  return (
    <>
      {/* <Header /> */}
      {/* <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)} /> */}
      {/* <DashboardLayoutWrapper> */}
        {/* <DashboardLayoutContainer> */}
          {/* <DashboardLayoutContent> */}
            <Outlet />
          {/* </DashboardLayoutContent> */}
        {/* </DashboardLayoutContainer> */}
      {/* </DashboardLayoutWrapper> */}
    </>
  );
};

export default DashboardLayout;
